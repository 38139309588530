class App {
  toggleMenus = document.querySelectorAll('.toggle-menu') as NodeListOf<HTMLElement>;
  menu = (document.querySelector('#responsive-menu') as HTMLElement).classList;

	constructor() {
	}

  start() {
    this.navigation()
		this.mediaVideo()
    this.initWA()
  }

	navigation() {
		this.toggleMenus.forEach(toggler => {
			toggler.addEventListener('click', () => {
				const timeout = this.menu.contains('open') ? 500 : 0
				setTimeout(() => this.menu.toggle('hidden'), timeout);
				this.menu.toggle('open')
			})
		})
	}

	mediaVideo() {
    const videos = document.querySelectorAll('.media-video') as NodeListOf<HTMLVideoElement>
		videos.forEach((v: HTMLVideoElement) => {
			try {
				v.muted = true
				v.load()
			} catch(e) {
				alert('Cannot play' + v.src + ': ' + e)
				v.controls = true
			}
		})
	}
  initWA(){
		const wa = document.querySelector('.wa-floating-button')
		if(wa!=null){
			wa.addEventListener('click',()=>{
				const p = atob(wa.getAttribute('data-wakey'));
				wa.setAttribute('href',"https://wa.me/" + p ) ;
			})
		}
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const app = new App()
	app.start()

	const main = document.querySelector('main') as HTMLElement
	const script = document.createElement('script')
	script.src = `assets/js/${main.id}.min.js`
	script.async = true
	script.type = 'module'
	document.body.appendChild(script)

	script.onload = (s) => {
		// console.log(s)
	}

	// script.onerror = () => {
	// 	console.error(`Error loading ${route}`)
	// }
})
